import React from "react"
import {Link} from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "reactstrap"
import styled from "styled-components"

const AboutPage = () => (
  <Layout>
    <SEO title="About Chicken Yard" />
    <Container fluid={true} className="p-0">
      <Row className="m-0">
        <Col className="p-0" />
      </Row>
    </Container>
    <Container fluid={true}>
      <Row>
        <Col className="about-content py-4">
          <AboutContent>
            <h1 className="text-center">
            About Chicken Yard
            </h1>
            <ShortLine />

            <p>Hi, and WELCOME to Chicken Yard! My name is Angelina and I’m passionate about raising healthy happy backyard chickens.</p>

<p>This site is all about 3 things:</p>

<ul>
<li><Link to="/topic/raising-chickens/">Raising Chickens</Link></li>
<li><Link to="/topic/coop-designs/">Chicken Coop Plans</Link></li>
<li><Link to="/topic/coop-kits/">Building a Chicken Coop</Link></li>
</ul>

<p>I built my first chicken coop 11 years ago. Since then I’ve built many more and I always have chickens in my backyard.</p>

<p>I encourage you to browse my website, read my tips and suggestions, learn from my experience, and <Link to="/contact/">get in contact</Link> if you have a question.</p>

<p><strong>Thanks for visiting! … Angelina</strong></p>
            
          </AboutContent>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default AboutPage

const ShortLine = styled.hr`
  width: 75%;
`
const AboutContent = styled.div`
  max-width: 665px;
  margin: auto;
  padding-bottom: ;
`
